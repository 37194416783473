<template>
  <ElvMessageBox
    ref="changeAssetPriceProviderDialog"
    :showCancelButton="true"
    :showCloseButton="false"
    :cancelButtonText="t('button.cancel')"
    :confirmButtonText="t('button.change')"
    :title="t('title.changePriceProvider')"
    class="elv-valuation-assets-change-asset-price-provider-dialog"
    @onConfirmEvent="onConfirmChangeAssetPriceProvider"
    @onCancelEvent="onCancelChangeAssetPriceProvider"
  >
    <template #content>
      <p>Do you want to switch the price provider?</p>
      <p>Once the price is specified for this asset, it cannot be changed.</p>
    </template>
  </ElvMessageBox>
</template>

<script setup lang="ts">
import ElvMessageBox from '@/components/Base/ElvMessageBox.vue'

const emit = defineEmits(['onConfirmChangeAssetPriceProvider', 'onCancelChangeAssetPriceProvider'])

const { t } = useI18n()
const changeAssetPriceProviderDialog = ref()

/**
 * 确认切换价格源
 */
const onConfirmChangeAssetPriceProvider = () => {
  changeAssetPriceProviderDialog.value.onCheckMessageBoxDialog()
  emit('onConfirmChangeAssetPriceProvider')
}

/**
 * 取消切换价格源
 */
const onCancelChangeAssetPriceProvider = () => {
  changeAssetPriceProviderDialog.value.onCheckMessageBoxDialog()
  emit('onCancelChangeAssetPriceProvider')
}

/**
 * 切换价格源弹窗显示状态
 */
const changeAssetPriceProviderDialogStatus = () => {
  changeAssetPriceProviderDialog.value.onCheckMessageBoxDialog()
}

defineExpose({
  changeAssetPriceProviderDialogStatus
})
</script>

<style lang="scss">
.elv-valuation-assets-change-asset-price-provider-dialog {
  .el-dialog__body {
    display: flex;
    flex-direction: column;
    color: #1e2024;
    text-align: center;
    font-family: 'Plus Jakarta Sans';
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
}
</style>
